var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-4 col-12 q-pr-md", attrs: { align: "left" } },
        [
          _c(
            "q-list",
            { attrs: { separator: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Area:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.area)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Prodotto:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.id_prodotto)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.guid_preventivo)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Descrizione preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.nominativo)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Stato della quotazione:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.getStato)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Unità Operativa:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.unita_operativa)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Operatore:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.operatore)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Telefono Ufficio #1:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.dati_ufficio.numero_telefono1)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Telefono Ufficio #2:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.dati_ufficio.numero_telefono2)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Email Ufficio #2:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.dati_ufficio.email)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "col-md-6 col-12" }, [
        _c(
          "fieldset",
          { staticClass: "q-mb-md", staticStyle: { width: "100%" } },
          [
            _c("legend", { staticClass: "q-pr-md q-pl-md" }, [
              _vm._v(" Informazioni "),
            ]),
            _c("br"),
            _c("QQButton", {
              attrs: {
                disabled: _vm.idQuestionarioDisabled,
                label: "Questionario assuntivo",
                color: "blue-grey",
                icon: "mdi-card-account-details-outline",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onVisualizzaQuestionarioAssuntivo.apply(
                    null,
                    arguments
                  )
                },
              },
            }),
            _c("QQButton", {
              attrs: {
                label: "Problemi segnalati",
                color: "blue-grey",
                icon: "mdi-flag-outline",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onVisualizzaProblemiSegnalati.apply(
                    null,
                    arguments
                  )
                },
              },
            }),
            _c("QQButton", {
              attrs: {
                label: "Registro movimenti",
                color: "blue-grey",
                icon: "mdi-grid-off",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onVisualizzaRegistroMovimenti.apply(
                    null,
                    arguments
                  )
                },
              },
            }),
            _c("QQButton", {
              attrs: {
                label: "Documenti allegati",
                color: "blue-grey",
                icon: "mdi-briefcase-upload-outline",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onVisualizzaElencoDocumenti.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "fieldset",
          { staticClass: "q-mb-md", staticStyle: { width: "100%" } },
          [
            _c("legend", { staticClass: "q-pr-md q-pl-md" }, [
              _vm._v(" Procedure "),
            ]),
            _c("br"),
            _c("QQButton", {
              attrs: {
                disabled: _vm.isDefinisciDisabled,
                label: "Definisci Quotazione",
                color: "blue-grey",
                icon: "mdi-grid-off",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onDefinisciQuotazione.apply(null, arguments)
                },
              },
            }),
            _c("QQButton", {
              attrs: {
                disabled: _vm.isRifiutaDisabled,
                label: "Rifiuta Quotazione",
                color: "blue-grey",
                icon: "mdi-trash-can-outline",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onRifiutaQuotazione.apply(null, arguments)
                },
              },
            }),
            _c("QQButton", {
              attrs: {
                disabled: _vm.isAnnullaDisabled,
                label: "Annulla Quotazione",
                color: "blue-grey",
                icon: "mdi-close-outline",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAnnullaQuotazione.apply(null, arguments)
                },
              },
            }),
            _c("br"),
            _c("QQButton", {
              attrs: {
                disabled: _vm.isAllegaDocumentiDisabled,
                label: "Allega documenti",
                color: "blue-grey",
                icon: "mdi-lan-pending",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onAllegaDocumenti.apply(null, arguments)
                },
              },
            }),
            _c("QQButton", {
              attrs: {
                disabled: _vm.isInviaSegnalazioneDisabled,
                label: "Invia segnalazione",
                color: "blue-grey",
                icon: "mdi-lead-pencil",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onInviaSegnalazione.apply(null, arguments)
                },
              },
            }),
            _c("QQButton", {
              attrs: {
                disabled: _vm.isInserisciAnnotazioneDisabled,
                label: "Inserisci annotazione",
                color: "blue-grey",
                icon: "mdi-newspaper-variant-outline",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onInserisciAnnotazione.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c(
      "div",
      { staticClass: "col-md-4", attrs: { align: "center" } },
      [
        _c("QQButton", {
          attrs: {
            label: "INDIETRO",
            color: "blue-grey",
            icon: "undo",
            size: "md",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onClickIndietro()
            },
          },
        }),
      ],
      1
    ),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Menu gestione Quotazioni"),
      ]),
      _c("br"),
      _vm._v(
        " Clicca su QUESTIONARIO ASSUNTIVO per fare i controlli e mettere la quotazione in lavorazione. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }